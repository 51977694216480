import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Input,
} from 'reactstrap'
import _ from 'lodash'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import AssignCourseModal from '../../../Components/Modals/AssignCourseModal'
import { SearchInput } from '../../../Components/Common/SearchInput'
import FilterTabs from './Tabs'
import {
  CoursesPermissions,
  GetUsersDTO,
  OrderType,
  TUser,
  USER_STATUSES,
  UserManagementPermissions,
  UsersSortBy,
} from '../../../sharedTypes'
import Filters from '../Filters'
import UsersTable, {
  IColumn,
  UserCourseStatus,
  UserId,
  UserInfo,
  UserStatus,
  UserRegistered,
  Departments,
} from '../../../Components/Common/UsersTable'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { getUsers } from '../../../helpers/api/users'
import { usePermissions } from '../../../hooks/usePermissions'
import moment from 'moment/moment'
import { useAppSelector } from '../../../hooks/redux'

const Columns: IColumn[] = [
  {
    title: 'ID',
    id: 'id',
    component: UserId,
    sortBy: UsersSortBy.ID,
    style: { width: 70 },
  },
  {
    title: 'User',
    id: 'user',
    component: UserInfo,
    sortBy: UsersSortBy.USER,
    style: { width: 220 },
  },
  {
    title: 'Facility',
    id: 'facility',
    sortBy: UsersSortBy.FACILITY_NAME,
    selector: 'facility.name',
  },
  {
    title: 'Agency',
    id: 'agency',
    sortBy: UsersSortBy.AGENCY_NAME,
    selector: 'agency.name',
  },
  {
    title: 'Department',
    id: 'department',
    component: Departments,
  },
  {
    title: 'Position',
    id: 'position',
    sortBy: UsersSortBy.POSITION_NAME,
    selector: 'position.name',
  },
  {
    title: 'Hire Date',
    id: 'hire-date',
    sortBy: UsersSortBy.HIRE_DATE,
    selector: 'hireDate',
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    component: UserCourseStatus,
    title: 'Status',
    id: 'status',
    style: { width: 150 },
    sortBy: UsersSortBy.COURSE_STATUS,
  },
  {
    component: UserStatus,
    title: 'Active',
    id: 'active',
    style: { width: 100 },
    sortBy: UsersSortBy.STATUS,
  },
  {
    component: UserRegistered,
    title: 'Registered',
    id: 'registered',
    style: { width: 100 },
    sortBy: UsersSortBy.REGISTERED,
  },
]

const UserListing = () => {
  document.title = 'User listing | Mastered - Admin & Dashboard'
  const user = useAppSelector(state => state.User.user)
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )

  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [data, setData] = useState<GetUsersDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    users: [],
  })

  const [query, setQuery] = useState<GetUsersDTO.Request>({
    page: 1,
    limit: 10,
    facilityIds: [],
    departmentIds: [],
    positionIds: [],
    courseStatus: undefined,
    sortBy: UsersSortBy.ID,
    orderBy: OrderType.ASC,
    permission: UserManagementPermissions.VIEW_USER_LISTING,
    status: [USER_STATUSES.ACTIVE],
    registeredStatuses: undefined,
  })

  const isFiltering = useMemo<boolean>(
    () =>
      _.size(query.positionIds) > 0 ||
      _.size(query.departmentIds) > 0 ||
      _.size(query.facilityIds) > 0 ||
      !!query.courseStatus ||
      !!query.status ||
      !!query.key,
    [query],
  )

  const [showAssign, setShowAssign] = useState(false)
  const hasPermissionToAssign = usePermissions(CoursesPermissions.ASSIGN_COURSE)

  const handleSort = useCallback((column: UsersSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const [selectedUsers, setSelectedUsers] = useState<TUser[]>([])
  const onSelect = useCallback((user: TUser) => {
    setSelectedUsers(prev => _.xorBy(prev, [user], 'id'))
  }, [])

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      getUsers(_.omitBy(query, _.isNil))
        .then(d => d.data)
        .then(res => {
          const usersIds = _.map(res.users, 'id')
          setSelectedUsers(prev => prev.filter(u => usersIds.includes(u.id)))
          setData(res)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  const columns = useMemo(() => {
    if (user && user?.isFacilityAdmin && !user?.hasAccessToMultipleFacilities) {
      return Columns.filter(column => column.title !== 'Facility')
    }
    return Columns
  }, [user])

  const isSelectedAll = useMemo(
    () => !_.differenceBy(data.users, selectedUsers, 'id').length,
    [data, selectedUsers],
  )

  useEffect(() => {
    if (!showFilters) {
      if (
        selectedFacilityId &&
        !_.isEqual([selectedFacilityId], query.facilityIds)
      ) {
        setQuery(q => ({
          ...q,
          facilityIds: [selectedFacilityId],
          groupIds: [],
        }))
      }

      if (selectedGroupId && !_.isEqual([selectedGroupId], query.groupIds)) {
        setQuery(q => ({ ...q, groupIds: [selectedGroupId], facilityIds: [] }))
      }
    }
  }, [selectedFacilityId, selectedGroupId, showFilters])

  const onToggleSelectedAll = useCallback(() => {
    if (isSelectedAll) {
      setSelectedUsers([])
    } else {
      setSelectedUsers(data.users)
    }
  }, [isSelectedAll, data.users])
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='User Listing'
            items={[
              {
                linkTo: '/',
                title: 'User Management',
              },
              {
                active: true,
                title: 'User Listing',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <div className='d-flex align-items-center gap-2 ps-2'>
                      <Input
                        className='form-check-input cursor-pointer m-0'
                        type='checkbox'
                        name={`selectCourses`}
                        id={`select-courses`}
                        checked={isSelectedAll}
                        onChange={e => {
                          onToggleSelectedAll()
                        }}
                      />
                      <Label
                        className='form-check-label text-primary'
                        htmlFor={`select-courses`}
                      >
                        Select all
                      </Label>
                    </div>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <FilterTabs
                      navTab={query.courseStatus}
                      navToggle={courseStatus => {
                        setQuery(prev => ({ ...prev, courseStatus, page: 1 }))
                      }}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      {hasPermissionToAssign && (
                        <Button
                          color={'primary'}
                          className='btn btn-primary align-middle'
                          onClick={() => setShowAssign(true)}
                        >
                          <i className='ri-edit-box-line me-1 fs-16'></i>Assign
                          Course
                        </Button>
                      )}
                    </div>
                  </div>
                  <Row
                    className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}
                  >
                    <Filters
                      visible={showFilters}
                      setFilters={data => {
                        setQuery(prev => ({
                          ...prev,
                          facilityIds: data.facility,
                          groupIds: data.groups,
                          agencyIds: data.agencies,
                          departmentIds: data.department,
                          positionIds: data.position,
                          status: data.status,
                          registeredStatuses: data.registeredStatuses,
                          page: 1,
                        }))

                        if (!fetchData) {
                          setFetchData(true)
                        }
                      }}
                      filterBy={{
                        departments: true,
                        positions: true,
                        agencies: true,
                        groups: false,
                        status: true,
                        registered: true,
                      }}
                    />
                  </Row>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={isFiltering}
                  pages={data.pages}
                >
                  <UsersTable
                    multiSelect={{
                      selectedUsers,
                      onSelect,
                    }}
                    columns={columns}
                    users={data.users}
                    sortedColumn={query.sortBy}
                    page={data.page - 1}
                    sortOrder={query.orderBy}
                    totalPages={data.pages}
                    onPageChanged={page => {
                      setQuery(prev => ({ ...prev, page: ++page }))
                    }}
                    onLimitChange={limit => {
                      setQuery(prev => ({ ...prev, limit }))
                    }}
                    limit={query.limit}
                    itemsPerPage={query.limit ? query.limit : 10}
                    handleSort={handleSort}
                    totalUsers={data.count}
                    globalSearch={query.key || ''}
                  />
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {hasPermissionToAssign && (
        <AssignCourseModal
          isOpen={showAssign}
          onClose={() => setShowAssign(false)}
          onSubmit={() => {}}
          selectedEntities={{
            courses: [],
            packages: [],
            trainingDays: [],
            users: selectedUsers,
          }}
        />
      )}
    </React.Fragment>
  )
}

export default UserListing
