import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import {
  COURSE_FILTER_FORMATS,
  COURSE_TYPES,
  CoursesPermissions,
  CoursesTabs,
  StatesEnum,
  ViewResultReportEnum,
} from '../../sharedTypes'
import {
  ONLINE_COURSE_STATUS,
  OnlineCourseItem,
  DigitalCourse,
  OnlineCourseFormatsEnum,
} from '../models'
import {
  CoursePackageFormatsEnum,
  CoursePackageStatus,
  TCoursePackage,
} from '../models/coursePackage'
import { PackagesSortBy } from './coursePackages'

export enum OnlineCategoriesSortBy {
  ID = 'ID',
  NAME = 'name',
  COURSES_COUNT = 'coursesCount',
  CODE = 'code',
}

export enum CoursesSortBy {
  CODE = 'code',
  NAME = 'name',
  TYPE = 'type',
  DESCRIPTION = 'description',
  CREDIT_HOURS = 'duration',
  DATE_PUBLISHED = 'publishedDate',
  CATEGORY = 'category.name',
  STATUS = 'status',
  UPDATED_AT = 'updatedAt',
}

export interface OnlineCategoryRequest {
  name: string
  code: string
  description: string
}

export interface OnlineCategoryItem extends OnlineCategoryRequest {
  id: number
  coursesCount: number
}

export namespace GetOnlineCoursesDTO {
  export interface Request extends PaginationRequest {
    sortBy?: CoursesSortBy
    statuses?: ONLINE_COURSE_STATUS[]
    states?: StatesEnum[]
    instructorIds?: number[]
    categoryIds?: number[]
    formats?: COURSE_FILTER_FORMATS[]
    types?: COURSE_TYPES[]
    notExpired?: boolean
  }

  export interface Response extends PaginatedBaseResponse {
    courses: OnlineCourseItem[]
  }
}

export namespace GetCoursesDTO {
  export interface Request extends PaginationRequest {
    activeTab: CoursesTabs
    sortBy?: CoursesSortBy | PackagesSortBy
    states?: StatesEnum[]
    types?: COURSE_TYPES[]
    statuses?: ONLINE_COURSE_STATUS[] | CoursePackageStatus[]
    format?: CoursePackageFormatsEnum
    publishedDate?: Date[]
    positionIds?: number[]
  }
  export interface Response extends PaginatedBaseResponse {
    data: OnlineCourseItem[] | TCoursePackage[]
  }
}

export namespace GetOnlineCategoriesDTO {
  export interface Request extends PaginationRequest {
    sortBy?: OnlineCategoriesSortBy
  }

  export interface Response extends PaginatedBaseResponse {
    online_categories: OnlineCategoryItem[]
  }
}

export namespace CreateOnlineCategoryDTO {
  export interface Request extends OnlineCategoryRequest {}
  export type Response = any
}

export namespace PatchOnlineCourseDTO {
  export type Response = OnlineCourseItem
}

export namespace PutDigitalCourseDTO {
  export type Response = DigitalCourse
  export type Request = {
    completeDays: number
    quizAttempts: number
  }
}

interface CompetencyMessage {
  languageId: number
  content: {
    success: string
    fail: string
    retake: string
  }
}

export namespace PutCompetencyGeneralCourseDTO {
  export type Response = DigitalCourse
  export type Request = {
    quizQuestionsNumber: number
    randomizeQuestions: boolean
    viewResultReport: ViewResultReportEnum
    messages: CompetencyMessage[]
  }
}

export namespace PostDefaultOnlineCourseDTO {
  export type Response = OnlineCourseItem
  export type Request = {
    name: string
    formats: OnlineCourseFormatsEnum[]
    type: COURSE_TYPES[]
  }
}

export namespace RemovetOnlineCourseFormatDTO {
  export type Response = OnlineCourseItem
  export type Request = {
    format: OnlineCourseFormatsEnum
  }
}
export namespace PublishOnlineCourseDTO {
  export type Response = OnlineCourseItem
}

export namespace PostOnlineCourseFormatDTO {
  export type Response = OnlineCourseItem
  export type Request = {
    format: OnlineCourseFormatsEnum
  }
}

export namespace GetOnlineCourseDTO {
  export type Request = {
    permission:
      | CoursesPermissions.ADD_EDIT_COURSE
      | CoursesPermissions.VIEW_TRANSLATIONS
  }
  export type Response = OnlineCourseItem
}

export namespace EditOnlineCategoryDTO {
  export interface Request extends OnlineCategoryRequest {}
  export type Response = any
}

export type OnlineCategoryInitialValues = {
  name: string
  code: string
  description: string
}

export type OnlineCategoryModalSubmitedValues = {
  name: string
  code: string
  description: string
}

export namespace CreateCourseRevisionDTO {
  export interface Request {
    expirationDate: string
  }
}
