import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert,
} from 'reactstrap'
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'

//formik
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ParticlesAuthHeader from '../../Components/Common/ParticlesAuthHeader'
import { ResetPasswordDTO } from '../../sharedTypes'
import { resetPassword } from '../../helpers/api_helper'
import axios from 'axios'
import withRouter, { WithRouterProps } from '../../Components/Common/withRouter'
import NotAdminBlock from '../../Components/Common/NotAdminBlock'

interface ResetPasswordProps extends WithRouterProps {
  isNew?: boolean
}

const ResetPassword = ({ router, isNew }: ResetPasswordProps) => {
  document.title =
    'Create New Password | Mastered - Premium Admin & Dashboard Platform'

  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const resetToken = router.params.token

  const submitForm = async (newPassword: string) => {
    if (!resetToken) {
      return
    }

    setLoading(true)
    setError(false)
    try {
      const payload: ResetPasswordDTO.Request = {
        token: resetToken,
        newPassword,
      }

      const { email } = await resetPassword(payload).then(res => res.data)

      router.navigate({
        pathname: '/lock',
        search: `?email=${encodeURIComponent(email)}`,
      })
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status) {
        setErrorMessage(error.response.data.message)
      }

      setError(true)
    }
    setLoading(false)
  }

  const form = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('This field is required'),
      confirm_password: Yup.string()
        .when('password', {
          is: (val?: string) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Both password need to be the same',
          ),
        })
        .required('Confirm Password Required'),
    }),
    onSubmit: values => {
      submitForm(values.password)
    },
  })
  return (
    <ParticlesAuth>
      <div className='auth-page-content'>
        <Container>
          <ParticlesAuthHeader marginTop='mt-0' paddingTop={'pt-4'} />

          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='mt-4'>
                <CardBody className='p-4'>
                  <div className='text-center mt-2'>
                    <h5 className='text-primary fw-normal'>
                      Create {isNew ? '' : 'new'} password
                    </h5>
                    <p className='text-muted fw-light fs-14 ff-base'>
                      {isNew
                        ? 'Create a password to access the Mastered admin portal.'
                        : 'Your new password must be different from previous used password.'}
                    </p>
                  </div>

                  <div className='p-2'>
                    {isError ? (
                      <Alert color='danger' style={{ marginTop: '13px' }}>
                        {errorMessage}
                      </Alert>
                    ) : null}
                    <Form onSubmit={form.handleSubmit} action='/login'>
                      <div className='mb-3'>
                        <div className='float-end text-muted fw-light'>
                          Must be at least 8 characters.
                        </div>
                        <Label className='form-label' htmlFor='password-input'>
                          {isNew ? 'New Password' : 'Password'}
                        </Label>
                        <div className='position-relative auth-pass-inputgroup'>
                          <Input
                            type={passwordShow ? 'text' : 'password'}
                            className='form-control pe-5 password-input'
                            placeholder='Enter password'
                            id='password-input'
                            name='password'
                            value={form.values.password}
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            invalid={
                              !!(form.errors.password && form.touched.password)
                            }
                          />
                          {form.errors.password && form.touched.password ? (
                            <FormFeedback type='invalid'>
                              {form.errors.password}
                            </FormFeedback>
                          ) : null}
                          <button
                            className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                            type='button'
                            onClick={() => setPasswordShow(!passwordShow)}
                            id='password-addon'
                          >
                            <i
                              className={`${
                                !passwordShow
                                  ? 'ri-eye-line'
                                  : 'ri-eye-off-line'
                              } align-middle`}
                            ></i>
                          </button>
                        </div>
                      </div>

                      <div className='mb-3'>
                        <Label
                          className='form-label'
                          htmlFor='confirm-password-input'
                        >
                          Confirm Password
                        </Label>
                        <div className='position-relative auth-pass-inputgroup mb-3'>
                          <Input
                            type={confirmPasswordShow ? 'text' : 'password'}
                            className='form-control pe-5 password-input'
                            placeholder='Confirm password'
                            id='confirm-password-input'
                            name='confirm_password'
                            value={form.values.confirm_password}
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            invalid={
                              !!(
                                form.errors.confirm_password &&
                                form.touched.confirm_password
                              )
                            }
                          />
                          {form.errors.confirm_password &&
                          form.touched.confirm_password ? (
                            <FormFeedback type='invalid'>
                              {form.errors.confirm_password}
                            </FormFeedback>
                          ) : null}
                          <button
                            className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                            type='button'
                            onClick={() =>
                              setConfirmPasswordShow(!confirmPasswordShow)
                            }
                            id='password-addon'
                          >
                            <i
                              className={`${
                                !confirmPasswordShow
                                  ? 'ri-eye-line'
                                  : 'ri-eye-off-line'
                              } align-middle`}
                            ></i>
                          </button>
                        </div>
                      </div>

                      {/*<div className='form-check'>
                        <Input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='auth-remember-check'
                        />
                        <Label
                          className='form-check-label'
                          htmlFor='auth-remember-check'
                        >
                          Remember me
                        </Label>
                      </div>*/}

                      <div className='mt-4'>
                        <Button
                          color='primary'
                          className='w-100'
                          type='submit'
                          disabled={!(form.isValid && form.dirty) || isLoading}
                        >
                          {isNew ? 'Create' : 'Reset'} Password
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {!isNew && (
                <div className='mt-4 text-center'>
                  <p className='mb-0 fw-normal'>
                    Wait, I remember my password...{' '}
                    <Link
                      to='/login'
                      className='text-primary text-decoration-underline fw-normal'
                    >
                      {' '}
                      Click here{' '}
                    </Link>
                  </p>
                </div>
              )}
              <div className='text-center'>
                <p className='fw-normal'>
                  <Link to={'/login'}>
                    <i className='ri-arrow-left-s-line me-1'></i> Back
                  </Link>
                </p>
              </div>
              <NotAdminBlock />
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  )
}

export default withRouter(ResetPassword)
