import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalProps,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  getAgencies,
  getDepartments,
  getFacilities,
  getLanguages,
  getPositions,
  getUsers,
} from '../../helpers/api_helper'
import classnames from 'classnames'
import {
  TLanguage,
  AssignTo,
  AssignToOptions,
  IAssignToOption,
  TUser,
  TPosition,
  TDepartment,
  TFacility,
  CommunicationPermissions,
} from '../../sharedTypes'
import {
  StatusTypes,
  TPushNotificationTranslationItem,
} from '../../sharedTypes/models/pushNotifications'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'
import Select from 'react-select'
import { AsyncSelectWithSearch } from '../Common/SelectWithSearch'
import { getUserDisplayName } from '../../helpers/user'
import WarningModal from './WarningModal'
import {
  getPushNotificationById,
  patchPushNotification,
  postPushNotification,
  sendPushNotification,
} from '../../helpers/api/pushNotifications'
import { toast } from 'react-toastify'
import { handleError, successToastOptions } from '../../helpers/toast_helper'
import { usePrevious } from '../../hooks/usePrevious'
import { usePermissions } from '../../hooks/usePermissions'
import { TAgency } from '../../sharedTypes/models/agency'
import { ENGLISH_LANGUAGE_CODE } from '../../helpers/common'

interface IForm {
  id?: number
  isDraft: boolean
  status: StatusTypes
  sendDate?: Date | undefined
  sendTo: AssignTo
  recipients: number[] | null
  link: string
  translations: TPushNotificationTranslationItem[]
}

interface NotificationModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: () => void
  id?: number | null
}

export interface IRecipientsData {
  value: string
  label: string
}

const PushNotificationModal = ({
  onClose,
  isOpen,
  onSubmit,
  id = null,
}: NotificationModalProps) => {
  const [languages, setLanguages] = useState<TLanguage[] | []>([])
  const [searchValue, setSearchValue] = useState('')
  const [activeTab, setActiveTab] = useState('en')
  const [sendToItem, setSendToItem] = useState<IAssignToOption>(
    AssignToOptions[0],
  )
  const [recipientsData, setRecipientsData] = useState<IRecipientsData[]>([])
  const [notificationRecipientsData, setNotificationRecipientsData] =
    useState<any>([])
  const [isOpenWarningModal, setIsOpenWarningModal] = useState<boolean>(false)
  const [params, setParams] = useState({
    page: 1,
    limit: 50,
    key: '',
  })
  const [optionsData, setOptionsData] = useState([])

  const prevSendToOption = usePrevious(sendToItem.value)

  const datePickerRef = useRef<Flatpickr>(null)

  const hasPermissionToSend = usePermissions(
    CommunicationPermissions.SEND_SCHEDULE_NOTIFICATIONS,
  )

  const _onSubmit = () => {
    const { translations, status } = form.values
    delete form.values.id

    // const values = translations.find(
    //   translation => translation.languageId === 2,
    // )
    // if ((!values?.title || !values?.message) && status !== StatusTypes.DRAFT) {
    //   setIsOpenWarningModal(true)
    //   form.setSubmitting(false)
    //   return
    // }

    const requestValues: any = {
      ...form.values,
      sendDate:
        status !== StatusTypes.SENT && form.values.sendDate
          ? form.values.sendDate
          : null,
      isDraft: status === StatusTypes.DRAFT,
      sendTo: sendToItem.value,
    }
    delete requestValues.status

    let action: Promise<any>

    if (id) {
      action = patchPushNotification(+id, requestValues)
      if (status === StatusTypes.SENT) {
        action.then(() => sendPushNotification(+id))
      }
    } else {
      action = postPushNotification(requestValues)
    }

    action
      .then(() => {
        const message = id
          ? status === StatusTypes.SENT
            ? 'Success - Notification has been sent!'
            : 'Success - Notification edited successfully'
          : `Success - Notification ${
              status === StatusTypes.DRAFT
                ? 'has been saved as draft!'
                : 'added successfully'
            }`
        toast(message, successToastOptions)
        onSubmit()
      })
      .catch(handleError)
      .finally(() => {
        form.setSubmitting(false)
      })
  }

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      status: StatusTypes.DRAFT,
      isDraft: false,
      recipients: [],
      sendTo: AssignTo.USERS,
      link: '',
      sendDate: undefined,
      translations: [],
    },
    validationSchema: Yup.object({
      translations: Yup.array(
        Yup.object({
          languageId: Yup.number().required(),
          title: Yup.string().when('languageId', {
            is: 1,
            then: Yup.string()
              .required('Please Enter Title')
              .max(100, 'Title should be under 100 characters'),
          }),
          message: Yup.string().when('languageId', {
            is: 1,
            then: Yup.string().required('Please Enter Message'),
          }),
        }),
      ),
      sendDate: Yup.date()
        .nullable()
        .when(['status'], {
          is: (status: StatusTypes) => [StatusTypes.SCHEDULED].includes(status),
          then: Yup.date()
            .required('Please select Schedule Date and Time')
            .min(new Date(), 'Schedule Date and Time must be in the future')
            .typeError('Please select Schedule Date and Time'),
        }),
      link: Yup.string()
        .nullable()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct link',
        ),
      recipients: Yup.array()
        .nullable()
        .when(['status'], {
          is: (status: StatusTypes) =>
            [StatusTypes.SCHEDULED, StatusTypes.SENT].includes(status),
          then: Yup.array()
            .of(Yup.number())
            .min(1, 'Please select at least one recipient.'),
        }),
    }),
    onSubmit: _onSubmit,
  })

  useEffect(() => {
    if (!isOpen) {
      form.resetForm()
      resetData()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      setRecipientsData([])
      form.setFieldValue(
        'recipients',
        notificationRecipientsData?.map((data: any) => data.id),
      )
      setSearchValue('')
    }
  }, [sendToItem])

  useEffect(() => {
    if (isOpen) {
      getLanguages()
        .then(data => {
          const _data = data.filter(item => item.code === ENGLISH_LANGUAGE_CODE)
          setLanguages(_data)
          if (!id) {
            const all: TPushNotificationTranslationItem[] = []
            _data.map(item => {
              all.push({ languageId: item.id, title: '', message: '' })
            })
            form.setFieldValue('translations', all)
          }
        })
        .catch(() => {})
    }
  }, [isOpen])

  useEffect(() => {
    if (id) {
      getPushNotificationById(+id).then(res => {
        const data: any = res.data.notification
        setNotificationRecipientsData(res.data.recipients)

        form.setValues({
          ...data,
          status: StatusTypes.DRAFT,
        })
        const sendTo = AssignToOptions.find(
          option => option.value === data.sendTo,
        )
        if (sendTo) {
          setSendToItem(sendTo)
        }
      })
    }
  }, [id])

  useEffect(() => {
    if (optionsData.length) {
      if (
        form.values.sendTo === sendToItem.value &&
        !recipientsData.length &&
        id
      ) {
        const recipientsData = optionsData.filter((option: any) =>
          (form.values.recipients ?? []).includes(option.value),
        )
        if (recipientsData) {
          setRecipientsData(recipientsData)
        }
      }
    }
  }, [optionsData])

  const tabChange = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const resetData = () => {
    setRecipientsData([])
    setSendToItem(AssignToOptions[0])
    setActiveTab('en')
    setParams({ ...params, page: 1, key: '' })
  }

  const dynamicInputsChange = (
    id: number,
    key: 'title' | 'message',
    value: string,
  ) => {
    const translations = form.values.translations

    const translationsData = translations?.find(
      translation => translation.languageId === id,
    )
    if (translationsData) {
      translationsData[key] = value
    } else {
      if (translations.length) {
        const translation = { languageId: id, title: '', message: '' }
        translations.push({ ...translation, [key]: value })
      }
    }
    if (translations.length) {
      form.setFieldValue('translations', [...translations])
    }
  }

  const fetchEntities = (inputValue?: string) => {
    if (!isOpen) {
      return
    }

    let action = null

    const sendTo = sendToItem.value

    const newParams: any =
      inputValue === searchValue
        ? { ...params, key: searchValue }
        : { ...params, key: inputValue, page: 1 }

    if (prevSendToOption !== sendToItem.value) {
      newParams.key = ''
    }

    if (sendTo === AssignTo.USERS) {
      action = getUsers({
        ...newParams,
        permission: id
          ? CommunicationPermissions.EDIT_NOTIFICATIONS
          : CommunicationPermissions.CREATE_CLONE_NOTIFICATIONS,
      }).then(res => res.data.users)
    } else if (sendTo === AssignTo.FACILITIES) {
      action = getFacilities(newParams).then(res => res.data.facilities)
    } else if (sendTo === AssignTo.DEPARTMENTS) {
      action = getDepartments(newParams).then(res => res.data.departments)
    } else if (sendTo === AssignTo.AGENCIES) {
      action = getAgencies(newParams).then(res => res.data.agencies)
    } else if (sendTo === AssignTo.POSITIONS) {
      action = getPositions({ ...newParams, groupBy: 'name' }).then(
        res => res.data.positions,
      )
    }

    if (action) {
      return action
        .then(entities => {
          if (
            newParams.page === 1 &&
            id &&
            form.values.sendTo === sendToItem.value
          ) {
            const existingIds = entities.map(
              (entity: { id: number }) => entity.id,
            )
            const newD = notificationRecipientsData.filter(
              (n: { id: number }) => !existingIds.includes(n.id),
            )
            entities = [...newD, ...entities]
          }

          const newData = entities.map(
            (
              entity: TUser | TPosition | TDepartment | TFacility | TAgency,
            ) => ({
              value: entity.id,
              label:
                'name' in entity
                  ? entity.name
                  : getUserDisplayName(entity) || '',
            }),
          )

          const backData =
            newParams.page === 1 ? newData : [...optionsData, ...newData]
          setOptionsData(backData as never)

          return backData
        })
        .catch(() => [])
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered
      className='push-notification-modal'
    >
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{id ? 'Edit' : 'Add new'}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <Row>
              <Col md={12}>
                <Nav className='nav-tabs-custom rounded' role='tablist'>
                  {languages.map((language, index) => (
                    <NavItem key={index}>
                      <NavLink
                        to='#'
                        className={classnames({
                          active: activeTab === language.code,
                        })}
                        onClick={() => {
                          tabChange(language.code)
                        }}
                      >
                        {language.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <TabContent activeTab={activeTab}>
                  {languages.map((language, index: number) => (
                    <TabPane tabId={language.code} key={index}>
                      <div className='vstack gap-4 mt-3'>
                        <div>
                          <label htmlFor='groupName' className='form-label'>
                            Title*
                          </label>
                          <Input
                            name='name'
                            className='form-control'
                            id='groupName'
                            placeholder='Enter name'
                            type='text'
                            onChange={e =>
                              dynamicInputsChange(
                                language.id,
                                'title',
                                e.target.value,
                              )
                            }
                            onBlur={form.handleBlur}
                            value={form.values.translations[index]?.title || ''}
                            invalid={
                              !!(
                                (form.errors?.translations as any)?.[index]
                                  ?.title &&
                                (form.touched?.translations as any)?.[index]
                                  ?.title
                              )
                            }
                          />
                          {(form.errors?.translations as any)?.[index]?.title &&
                          (form.touched?.translations as any)?.[index]
                            ?.title ? (
                            <FormFeedback type='invalid'>
                              {
                                (form.errors?.translations as any)?.[index]
                                  ?.title
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor='description' className='form-label'>
                            Message*
                          </label>
                          <Input
                            name='message'
                            className='form-control'
                            id='groupName'
                            type='textarea'
                            onChange={e =>
                              dynamicInputsChange(
                                language.id,
                                'message',
                                e.target.value,
                              )
                            }
                            onBlur={form.handleBlur}
                            value={
                              form.values.translations[index]?.message || ''
                            }
                            invalid={
                              !!(
                                (form.errors?.translations as any)?.[index]
                                  ?.message &&
                                (form.touched?.translations as any)?.[index]
                                  ?.message
                              )
                            }
                          />
                          {(form.errors?.translations as any)?.[index]
                            ?.message &&
                          (form.touched?.translations as any)?.[index]
                            ?.message ? (
                            <FormFeedback type='invalid'>
                              {
                                (form.errors?.translations as any)?.[index]
                                  ?.message
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </TabPane>
                  ))}
                  <div className='vstack gap-4 mt-3'>
                    <div>
                      <label htmlFor='sendTo' className='form-label'>
                        Send To*
                      </label>
                      <Select<IAssignToOption, false>
                        name='sendTo'
                        id='sendTo'
                        isSearchable={false}
                        onChange={option => {
                          if (option) {
                            setSendToItem(option)
                          }
                        }}
                        onBlur={form.handleBlur}
                        value={sendToItem}
                        options={AssignToOptions}
                        className='select2-container'
                        classNamePrefix='select2-selection form-select'
                      />
                    </div>

                    <div>
                      <label htmlFor='recipients' className='form-label'>
                        {
                          AssignToOptions.find(
                            item => item.value === sendToItem.value,
                          )?.label
                        }
                        *
                      </label>
                      {(form.values.id && id) || !id ? (
                        <>
                          <AsyncSelectWithSearch
                            key={sendToItem.value + params.page}
                            name='recipients'
                            id='recipients'
                            onChange={option => {
                              setRecipientsData(option)
                              form.setFieldValue(
                                'recipients',
                                option.map((o: IRecipientsData) => o.value),
                              )
                            }}
                            onBlur={form.handleBlur}
                            value={recipientsData}
                            isMulti={true}
                            isClearable={false}
                            isSearchable={true}
                            placeholder={''}
                            defaultOptions
                            loadOptions={fetchEntities}
                            onMenuScrollToBottom={() => {
                              if (optionsData.length >= params.limit) {
                                setParams({ ...params, page: ++params.page })
                              }
                            }}
                            inputValue={searchValue}
                            onInputChange={newInputValue => {
                              setSearchValue(newInputValue)
                            }}
                          />

                          {form.touched.recipients && form.errors.recipients ? (
                            <div className='invalid-feedback d-block'>
                              {form.errors.recipients}
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div>
                          <Spinner />
                        </div>
                      )}
                    </div>
                    <div>
                      <label htmlFor='link' className='form-label'>
                        Link
                      </label>
                      <Input
                        name='link'
                        className='form-control'
                        id='link'
                        placeholder='Enter link'
                        type='text'
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.link || ''}
                        invalid={!!form.errors?.link && form.touched?.link}
                      />
                      {form.touched.link && form.errors.link ? (
                        <FormFeedback type='invalid'>
                          {form.errors.link}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div>
                      <label htmlFor='date' className='form-label'>
                        Scheduled Date and Time
                      </label>
                      <div className='form-icon right'>
                        <Flatpickr
                          data-enable-time
                          className={`form-control form-control-icon ${
                            form.errors.sendDate &&
                            form.values.status === StatusTypes.SCHEDULED
                              ? ' is-invalid'
                              : ''
                          }`}
                          ref={datePickerRef}
                          id='date'
                          name='sendDate'
                          placeholder='mm-dd-yyyy, --:--'
                          onChange={option => {
                            form.setFieldValue('sendDate', option[0])
                          }}
                          onBlur={() => {
                            form.setFieldTouched('sendDate', true)
                          }}
                          value={form.values.sendDate}
                          options={{
                            dateFormat: 'MM-DD-YYYY HH:mm',
                            formatDate: date =>
                              moment(date).format('MM/DD/YYYY HH:mm'),
                            minDate: Date.now(),
                            allowInvalidPreload: true,
                          }}
                        />
                        {form.values.sendDate && (
                          <i
                            className='ri-close-line fs-16 text-danger'
                            onClick={() => {
                              datePickerRef.current?.flatpickr.clear()
                            }}
                          ></i>
                        )}
                        <i className='ri-calendar-event-line fs-20'></i>
                      </div>
                      {form.errors.sendDate &&
                      form.values.status === StatusTypes.SCHEDULED ? (
                        <div className='invalid-feedback d-block'>
                          {form.errors.sendDate}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </TabContent>
              </Col>
            </Row>
          </div>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              color='light'
              onClick={onClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>

            <Button
              className='btn-soft-primary'
              active={false}
              disabled={form.isSubmitting}
              onClick={() => {
                form.setFieldValue('status', StatusTypes.DRAFT)
                form.validateForm().finally(() => {
                  return form.submitForm()
                })
              }}
            >
              Save as Draft
            </Button>
            {hasPermissionToSend && (
              <>
                <Button
                  color='success'
                  className='right'
                  disabled={!form.dirty || form.isSubmitting}
                  onClick={() => {
                    form.setFieldValue('status', StatusTypes.SCHEDULED)
                    form.validateForm().finally(() => {
                      return form.submitForm()
                    })
                  }}
                >
                  Schedule
                </Button>
                <Button
                  color='primary'
                  disabled={!form.dirty || form.isSubmitting}
                  onClick={() => {
                    form.setFieldValue('status', StatusTypes.SENT)
                    form.validateForm().finally(() => {
                      return form.submitForm()
                    })
                  }}
                >
                  Send Now
                </Button>
              </>
            )}
          </div>
        </Form>
      </ModalBody>

      <WarningModal
        isOpen={isOpenWarningModal}
        title=''
        message={
          'Please fill Spanish translations before saving the notification.'
        }
        onClose={() => {
          setIsOpenWarningModal(false)
          form.setSubmitting(false)
        }}
      />
    </Modal>
  )
}

export default PushNotificationModal
