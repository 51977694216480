import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import {
  Input,
  Col,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  Row,
  Label,
  Card,
  Button,
} from 'reactstrap'
import InstructorModal from '../../../../Components/Modals/InstructorModal'
import Select from 'react-select'
import _ from 'lodash'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
  StatesOptions,
  AttachmentsTypesEnum,
  TInstructor,
  COURSE_TYPES,
  OnlineTagModalSubmitedValues,
} from '../../../../sharedTypes'
import { AsyncSelectWithSearch } from '../../../../Components/Common/SelectWithSearch'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import SelectFromMediaLibraryModal from '../../../../Components/Modals/MediaModal'
import { Link } from 'react-router-dom'
import ImageCropperModal from '../../../../Components/Modals/ImageCropperModalv2'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { CourseLayout } from '../../../../Components/Course'
import { GeneralAddFormat } from '../../../../Components/Course'
import { CourseContext } from '../../../../context/CourseContext'
import { getUserDisplayName } from '../../../../helpers/user'
import { isAllOption } from '../../../../hooks/course/generalStep'
import { AllValue } from '../../../../Components/Modals/AssignCourseModal/types'
import { TAttachment } from '../../../../sharedTypes'
import { getCoursePositions } from '../../../../helpers/api/coursePositions'
import { CoursePosition } from '../../../../sharedTypes/api/coursePositions'
import MultiSelect from '../../../../Components/Common/MultiSelect'
import OnlineTagModal from '../../OnlineTags/OnlineTagModal'
import { FormikHelpers } from 'formik'
import { postOnlineTags } from '../../../../helpers/api_helper'
import {
  errorToastOptions,
  handleError,
  successToastOptions,
} from '../../../../helpers/toast_helper'
import { toast } from 'react-toastify'

const levelOptons = [
  {
    label: 'Beginner',
    value: 'beginner',
  },
  {
    label: 'Intermediate',
    value: 'intermediate',
  },
  {
    label: 'Advanced',
    value: 'advanced',
  },
]

interface GenerateStepProps extends WithRouterProps {}

const GeneratStep = ({ router }: GenerateStepProps) => {
  const {
    course,
    setCourseId,
    generalStep,
    onAddFormat,
    goNext,
    onBack,
    onNavigate,
    tabs,
    isPublished,
  } = useContext(CourseContext)
  const {
    form,
    languages,
    selectedLanguages,
    selectedLanguage,
    setSelectedLanguage,
    onDeleteCover,
    fetchInstructors,
    fetchOnlineCategories,
    fetchTags,
    handleLanguageChange,
    translationError,
    translationTabError,
    mediaModalDetails,
    setMediaModalDetails,
    hasUnsavedData,
    onPreHandleSubmit,
    onDiscardChanges,
    handleCropSave,
  } = generalStep

  const datePickerRef = useRef<Flatpickr>(null)
  const [showCreateInstructorModal, setCreateInstructorModal] =
    useState<boolean>(false)
  const [showCreateKeywordsModal, setCreateKeywordsModal] =
    useState<boolean>(false)
  const [coursePositions, setCoursePositions] = useState<CoursePosition[]>([])

  useEffect(() => {
    setCourseId(Number(router.params.courseId))
  }, [router.params.courseId])

  const [croperAttachment, setCroperAttachment] = useState<TAttachment | null>(
    null,
  )

  const onCreateInstructor = (instructor: TInstructor) => {
    form.setFieldValue('instructor', {
      label: getUserDisplayName(instructor),
      value: instructor.id,
    })
    setCreateInstructorModal(false)
  }

  const courseTypeOptions = Object.values(COURSE_TYPES).map((t: string) => ({
    value: t as string,
    label: t as string,
  }))

  const handleUploadSelectedMedia = (attachment: TAttachment) => {
    setCroperAttachment(attachment)
  }

  const selectedLanguageIndex = useMemo(
    () =>
      form.values.translations.findIndex(
        t => selectedLanguage === t.languageId,
      ) || 0,
    [form.values.translations],
  )

  const showLevel = useMemo(() => {
    if (Array.isArray(form.values.type) && form.values.type.length > 0) {
      return ![COURSE_TYPES.ANNUAL_EDUCATION, COURSE_TYPES.ONBOARDING].includes(
        form.values.type[0],
      )
    }
    return false
  }, [form.values.type])

  const scondsToMinutes = (seconds: number) => {
    return moment.utc(seconds * 1000).format('HH:mm')
  }

  const fetchCoursePositions = () => {
    return getCoursePositions()
      .then(res => {
        setCoursePositions(res.coursePositions)
        return res.coursePositions.map(position => ({
          value: position.id,
          label: position.name,
        }))
      })
      .catch(() => [])
  }

  const onTagCreate = useCallback(
    async (
      values: OnlineTagModalSubmitedValues,
      form: FormikHelpers<OnlineTagModalSubmitedValues>,
    ) => {
      try {
        await postOnlineTags(values)
        form.resetForm()
        setCreateKeywordsModal(false)
        toast('Success - Tag successfully added.', successToastOptions)
      } catch (e) {
        form.resetForm()
        handleError(e)
      }
    },
    [],
  )

  return (
    <CourseLayout
      tabs={tabs}
      showSaveAsDraft
      backText='Cancel'
      onNavigate={direction => {
        if (!hasUnsavedData) {
          onDiscardChanges()
          onNavigate(direction)
        } else {
          onPreHandleSubmit({ direction })
        }
      }}
      hasUnsavedData={false}
      router={router}
      course={course}
      onBack={() => {
        onBack()
      }}
      onNext={() => {
        if (!hasUnsavedData) {
          onDiscardChanges()
          goNext()
        } else {
          onPreHandleSubmit({ direction: 'next' })
        }
      }}
      onSaveAsDraft={props => {
        onPreHandleSubmit({ direction: 'courses' })
      }}
    >
      <>
        <Row>
          <Col sm='6'>
            <Row className='gap-3 w-100 h-100'>
              {/*<Col sm='12'>*/}
              {/*  <div className='gap-3 w-100 row'>*/}
              {/*    <label htmlFor='tutorials' className='form-label'>*/}
              {/*      Languages*/}
              {/*    </label>*/}
              {/*    <Select*/}
              {/*      name='languages'*/}
              {/*      id='languages'*/}
              {/*      isMulti*/}
              {/*      options={languages}*/}
              {/*      styles={{*/}
              {/*        control: baseStyles => ({*/}
              {/*          ...baseStyles,*/}
              {/*          borderRight:*/}
              {/*            form.touched.languages && form.errors.languages*/}
              {/*              ? '1 !important'*/}
              {/*              : undefined,*/}
              {/*          borderColor:*/}
              {/*            form.touched.languages && form.errors.languages*/}
              {/*              ? '#f06548 !important'*/}
              {/*              : undefined,*/}
              {/*        }),*/}
              {/*      }}*/}
              {/*      isSearchable={true}*/}
              {/*      onBlur={() => {*/}
              {/*        form.setFieldTouched('languages')*/}
              {/*      }}*/}
              {/*      placeholder='Choose languages'*/}
              {/*      onChange={handleLanguageChange}*/}
              {/*      value={selectedLanguages}*/}
              {/*      className='select2-container is-invalid'*/}
              {/*      classNamePrefix='select2-selection form-select'*/}
              {/*    />*/}
              {/*    {form.touched.languages && form.errors.languages ? (*/}
              {/*      <FormFeedback type='invalid'>*/}
              {/*        {form.errors.languages}*/}
              {/*      </FormFeedback>*/}
              {/*    ) : null}*/}
              {/*  </div>*/}
              {/*</Col>*/}

              <Col sm='12'>
                <Row className='gap-3 w-100'>
                  <Col sm='12'>
                    <div>
                      <label htmlFor='title' className='form-label'>
                        Name*
                      </label>
                      <Input
                        className='form-control'
                        id='title'
                        name={`translations[${selectedLanguageIndex}]['name']`}
                        placeholder='Enter name'
                        type='text'
                        value={
                          (
                            form.values['translations'][
                              selectedLanguageIndex
                            ] as any
                          )?.name
                        }
                        invalid={
                          !!translationError(selectedLanguageIndex, 'name')
                        }
                        onChange={e => {
                          form.setFieldValue(
                            `translations[${selectedLanguageIndex}]['name']`,
                            e.target.value,
                          )
                        }}
                        onBlur={() =>
                          form.setFieldTouched(
                            `translations[${selectedLanguageIndex}]['name']`,
                          )
                        }
                      />
                      {translationError(selectedLanguageIndex, 'name')}
                    </div>
                  </Col>
                  <Col sm='12'>
                    <div>
                      <label htmlFor='description' className='form-label'>
                        Description
                      </label>
                      <Input
                        className='form-control'
                        id='description'
                        placeholder='Enter description'
                        type='textarea'
                        rows='6'
                        value={
                          (
                            form.values['translations'][
                              selectedLanguageIndex
                            ] as any
                          )?.description
                        }
                        invalid={
                          !!translationError(
                            selectedLanguageIndex,
                            'description',
                          )
                        }
                        onChange={form.handleChange(
                          `translations[${selectedLanguageIndex}].description`,
                        )}
                        onBlur={form.handleBlur(
                          `translations[${selectedLanguageIndex}].description`,
                        )}
                      />
                      {translationError(selectedLanguageIndex, 'description')}
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <label htmlFor='description' className='form-label'>
                        Objectives
                      </label>
                      <div className='is-invalid'>
                        {' '}
                        <CKEditor
                          editor={ClassicEditor}
                          data={
                            (
                              form.values['translations'][
                                selectedLanguageIndex
                              ] as any
                            )?.objective
                          }
                          onReady={editor => {
                            // You can store the 'editor' and use when it is needed.
                          }}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData()
                            form.setFieldValue(
                              `translations[${selectedLanguageIndex}].objective`,
                              data,
                            )
                          }}
                          onBlur={() =>
                            form.handleBlur(
                              `translations[${selectedLanguageIndex}].objective`,
                            )
                          }
                        />
                      </div>
                      {translationError(selectedLanguageIndex, 'objective')}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm='6'>
            <Row style={{ rowGap: 15 }} className='h-100'>
              <Col sm='6'>
                <div>
                  <label htmlFor='title' className='form-label'>
                    Code
                  </label>
                  <Input
                    className='form-control'
                    id='title'
                    disabled
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.code}
                    name='code'
                    placeholder=''
                    type='text'
                  />
                  {form.touched.code && form.errors.code ? (
                    <FormFeedback type='invalid'>
                      {form.errors.code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col sm='6'>
                <div>
                  <label htmlFor='tutorials' className='form-label'>
                    Valid States
                  </label>
                  <Select
                    name='states'
                    id='states'
                    isMulti={!isAllOption(form.values.states)}
                    options={[AllValue, ...StatesOptions]}
                    isSearchable={true}
                    placeholder='Select states'
                    onChange={e => {
                      form.setFieldValue(
                        'states',
                        isAllOption(e) ? [AllValue] : _.isArray(e) ? e : [e],
                      )
                    }}
                    onBlur={form.handleBlur}
                    value={form.values.states}
                    className='select2-container is-invalid'
                    classNamePrefix='select2-selection form-select'
                  />
                  {form.touched.states && form.errors.states ? (
                    <FormFeedback type='invalid'>
                      {form.errors.states as string}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col sm='6'>
                <div>
                  <label className='form-label'>Type*</label>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <MultiSelect
                        name='type'
                        id='type'
                        placeholder='Select a Type'
                        value={courseTypeOptions.filter(option =>
                          form.values.type.includes(
                            option.value as COURSE_TYPES,
                          ),
                        )}
                        options={courseTypeOptions}
                        onChange={(selectedOptions: any[]) => {
                          const selectedValues = selectedOptions.map(
                            item => item.value,
                          )
                          form.setFieldValue('type', selectedValues)
                        }}
                        isMulti
                        isClearable={false}
                        isSearchable
                      />
                      {form.touched.type && form.errors.type ? (
                        <FormFeedback type='invalid'>
                          {form.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm='6'>
                <div>
                  <label htmlFor='tutorials' className='form-label'>
                    Positions
                  </label>
                  <AsyncSelectWithSearch
                    name='positionIds'
                    id='positionIds'
                    isMulti={true}
                    isClearable={false}
                    loadOptions={fetchCoursePositions}
                    isSearchable={false}
                    placeholder='Select positions'
                    defaultOptions
                    onChange={option => {
                      form.setFieldValue(
                        'positionIds',
                        _.isEmpty(option) ? [] : _.map(option, 'value'),
                      )
                    }}
                    onBlur={form.handleBlur}
                    value={form.values.positionIds.map(id => ({
                      value: id,
                      label: coursePositions.find(p => p.id === id)?.name || '',
                    }))}
                    className='select2-container is-invalid'
                    classNamePrefix='select2-selection form-select'
                  />
                </div>
              </Col>
              {showLevel && (
                <Col sm='6'>
                  <div>
                    <label htmlFor='level' className='form-label'>
                      Course Level
                    </label>
                    <Select
                      name='level'
                      id='level'
                      options={levelOptons as any}
                      placeholder='Select level'
                      onChange={e => {
                        form.setFieldValue('level', e)
                      }}
                      onBlur={() => {
                        form.setFieldTouched('level')
                      }}
                      value={form.values.level}
                      className='select2-container  is-invalid'
                      classNamePrefix='select2-selection form-select'
                    />
                    {form.touched.level && form.errors.level ? (
                      <FormFeedback type='invalid'>
                        {form.errors.level}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              )}

              <Col sm={showLevel ? '6' : '12'}>
                <div>
                  <label htmlFor='title' className='form-label'>
                    Duration
                  </label>
                  <Flatpickr
                    data-enable-time
                    className={`form-control form-control-icon ${
                      form.touched.duration && form.errors.duration
                        ? ' is-invalid'
                        : ''
                    }`}
                    id='duration'
                    name='duration'
                    onChange={option => {
                      form.setFieldValue(
                        'duration',
                        moment
                          .duration(moment(option[0]).format('HH:mm'))
                          .asSeconds(),
                      )
                    }}
                    onBlur={() => {
                      form.setFieldTouched('duration', true)
                    }}
                    placeholder=''
                    value={scondsToMinutes(form.values.duration)}
                    options={{
                      dateFormat: 'H:i',
                      allowInvalidPreload: true,
                      noCalendar: true,
                      time_24hr: true,
                      enableTime: true,
                    }}
                  />
                  {form.touched.duration && form.errors.duration ? (
                    <FormFeedback type='invalid'>
                      {form.errors.duration}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm='12'>
                <div>
                  <label className='form-label'>Instructor</label>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <AsyncSelectWithSearch
                        styles={{
                          control: baseStyles => ({
                            ...baseStyles,
                            borderRadius: '4px 0px 0px 4px',
                            minHeight: 39,
                            borderColor:
                              form.touched.instructorId &&
                              form.errors.instructorId
                                ? '#f06548 !important'
                                : undefined,
                          }),
                        }}
                        placeholder='Select instructor'
                        className='select2-container is-invalid'
                        name='instructorId'
                        id='instructorId'
                        onChange={option =>
                          form.setFieldValue('instructorId', option)
                        }
                        onBlur={() => {
                          form.setFieldTouched('instructorId', true)
                        }}
                        value={form.values.instructorId}
                        isMulti={false}
                        isClearable={true}
                        isSearchable={true}
                        defaultOptions
                        loadOptions={fetchInstructors}
                        error={
                          form.touched.instructorId
                            ? form.errors.instructorId
                            : undefined
                        }
                      />
                    </div>
                    <div>
                      <div
                        className='btn btn-light w-auto text-nowrap border-1'
                        style={{
                          borderColor: '#CED4DA',
                          borderLeft: 0,
                          borderRadius: '0px 4px 4px 0px',
                        }}
                        onClick={() => setCreateInstructorModal(true)}
                      >
                        Add instructor
                      </div>
                    </div>
                  </div>
                  {form.touched.instructorId && form.errors.instructorId ? (
                    <FormFeedback type='invalid'>
                      {form.errors.instructorId}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm='12'>
                <div>
                  <label className='form-label'>Keywords</label>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <AsyncSelectWithSearch
                        styles={{
                          control: baseStyles => ({
                            ...baseStyles,
                            borderRadius: '4px 0px 0px 4px',
                            minHeight: 39,
                            borderColor:
                              form.touched.tagIds && form.errors.tagIds
                                ? '#f06548 !important'
                                : undefined,
                          }),
                        }}
                        placeholder='Select keyword'
                        className='select2-container is-invalid'
                        name='tagIds'
                        id='tagIds'
                        onChange={option => {
                          form.setFieldValue('tagIds', option)
                        }}
                        onBlur={() => {
                          form.setFieldTouched('tagIds', true)
                        }}
                        value={form.values.tagIds}
                        isMulti
                        isClearable={true}
                        isSearchable={true}
                        defaultOptions
                        loadOptions={fetchTags}
                        error={
                          form.touched.tagIds
                            ? form.errors.tagIds?.toString()
                            : undefined
                        }
                      />
                    </div>
                    <div>
                      <div
                        className='btn btn-light w-auto text-nowrap border-1'
                        style={{
                          borderColor: '#CED4DA',
                          borderLeft: 0,
                          borderRadius: '0px 4px 4px 0px',
                        }}
                        onClick={() => setCreateKeywordsModal(true)}
                      >
                        Add Keyword
                      </div>
                    </div>
                  </div>
                  {form.touched.tagIds && form.errors.tagIds ? (
                    <FormFeedback type='invalid'>
                      {form.errors.tagIds.toString()}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label htmlFor='date' className='form-label'>
                    Effective Date
                  </label>
                  <div
                    className={`form-icon right ${
                      form.touched.effectiveDate && form.errors.effectiveDate
                        ? ' is-invalid'
                        : ''
                    }`}
                  >
                    <Flatpickr
                      className={`form-control form-control-icon ${
                        form.touched.effectiveDate && form.errors.effectiveDate
                          ? ' is-invalid'
                          : ''
                      }`}
                      ref={datePickerRef}
                      id='date'
                      name='effectiveDate'
                      placeholder='mm/dd/yyyy'
                      onChange={option => {
                        form.setFieldValue('effectiveDate', option[0])
                      }}
                      onBlur={() => {
                        form.setFieldTouched('effectiveDate', true)
                      }}
                      value={
                        form.values.effectiveDate &&
                        moment(form.values.effectiveDate).toISOString()
                      }
                      disabled={
                        !!form.values.effectiveDate &&
                        moment().isSameOrAfter(
                          moment(form.values.effectiveDate),
                        )
                      }
                      options={{
                        dateFormat: 'mm/dd/yyyy',
                        formatDate: date => moment(date).format('L'),
                        minDate:
                          moment().isBefore(
                            moment(form.values.effectiveDate),
                          ) || !form.values.effectiveDate
                            ? Date.now()
                            : undefined,
                        allowInvalidPreload: true,
                      }}
                    />
                    <i className='ri-calendar-event-line fs-20'></i>
                  </div>
                  {form.touched.effectiveDate && form.errors.effectiveDate ? (
                    <FormFeedback type='invalid'>
                      {form.errors.effectiveDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div>
                  <label htmlFor='date' className='form-label'>
                    Expiration Date
                  </label>
                  <div
                    className={`form-icon right ${
                      form.touched.expirationDate && form.errors.expirationDate
                        ? ' is-invalid'
                        : ''
                    }`}
                  >
                    <Flatpickr
                      className={`form-control form-control-icon ${
                        form.touched.expirationDate &&
                        form.errors.expirationDate
                          ? ' is-invalid'
                          : ''
                      }`}
                      ref={datePickerRef}
                      id='date'
                      name='expirationDate'
                      placeholder='mm/dd/yyyy'
                      disabled={
                        !!form.values.expirationDate &&
                        moment().isAfter(moment(form.values.expirationDate))
                      }
                      onChange={option => {
                        form.setFieldValue('expirationDate', option[0])
                      }}
                      onBlur={() => {
                        form.setFieldTouched('expirationDate', true)
                      }}
                      value={
                        form.values.expirationDate &&
                        moment(form.values.expirationDate).toISOString()
                      }
                      options={{
                        dateFormat: 'mm/dd/yyyy',
                        formatDate: date => moment(date).format('L'),
                        minDate:
                          moment().isSameOrBefore(
                            moment(form.values.expirationDate),
                          ) || !form.values.expirationDate
                            ? Date.now()
                            : undefined,
                        allowInvalidPreload: true,
                      }}
                    />
                    <i className='ri-calendar-event-line fs-20'></i>
                  </div>
                  {form.touched.expirationDate && form.errors.expirationDate ? (
                    <FormFeedback type='invalid'>
                      {form.errors.expirationDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col col='4'>
                <div>
                  <label htmlFor='bio' className='form-label'>
                    Certificate Required
                  </label>
                  <div className='form-check mb-2'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      name='certificateRequired'
                      checked={form.values.certificateRequired}
                      onChange={() => {
                        form.setFieldValue('certificateRequired', true)
                      }}
                      id='flexRadioDefault1'
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='flexRadioDefault1'
                    >
                      Yes
                    </Label>
                  </div>
                  <div className='form-check'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      name='certificateRequired'
                      checked={!form.values.certificateRequired}
                      onChange={() => {
                        form.setFieldValue('certificateRequired', false)
                      }}
                      defaultChecked
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='flexRadioDefault2'
                    >
                      No
                    </Label>
                  </div>
                </div>
              </Col>

              <Col sm='4'>
                <div>
                  <label htmlFor='bio' className='form-label'>
                    Include Feedback Survey
                  </label>
                  <div className='form-check mb-2'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      name='feedbackSurvey'
                      checked={form.values.feedbackSurvey}
                      onChange={() => {
                        form.setFieldValue('feedbackSurvey', true)
                      }}
                      id='flexRadioDefault1'
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='flexRadioDefault1'
                    >
                      Yes
                    </Label>
                  </div>
                  <div className='form-check'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      name='feedbackSurvey'
                      checked={!form.values.feedbackSurvey}
                      onChange={() => {
                        form.setFieldValue('feedbackSurvey', false)
                      }}
                      defaultChecked
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='flexRadioDefault2'
                    >
                      No
                    </Label>
                  </div>
                </div>
              </Col>

              <Col sm='4'>
                <div>
                  <label htmlFor='feedbackAnonymous' className='form-label'>
                    Allow Anonymous Feedback
                  </label>
                  <div className='form-check mb-2'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      name='feedbackAnonymous'
                      checked={form.values.feedbackAnonymous}
                      onChange={() => {
                        form.setFieldValue('feedbackAnonymous', true)
                      }}
                      id='flexRadioDefault1'
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='flexRadioDefault1'
                    >
                      Yes
                    </Label>
                  </div>
                  <div className='form-check'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      name='feedbackAnonymous'
                      checked={!form.values.feedbackAnonymous}
                      onChange={() => {
                        form.setFieldValue('feedbackAnonymous', false)
                      }}
                      defaultChecked
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='flexRadioDefault2'
                    >
                      No
                    </Label>
                  </div>
                </div>
              </Col>

              <SelectFromMediaLibraryModal
                isOpen={mediaModalDetails.isOpen}
                type={mediaModalDetails.type}
                handleUploadSelectedMedia={handleUploadSelectedMedia}
                onClose={() => {
                  setMediaModalDetails((prev: any) => ({
                    ...prev,
                    isOpen: false,
                  }))
                  form.setSubmitting(false)
                }}
              />

              <ImageCropperModal
                show={!!croperAttachment}
                onClose={() => setCroperAttachment(null)}
                attachment={croperAttachment}
                aspectRatios={[2.6, 1.7]}
                onSave={(attachment, images) => {
                  handleCropSave(attachment, images)
                  setCroperAttachment(null)
                }}
              />

              <Col sm='12'>
                <div>
                  <label htmlFor='title' className='form-label'>
                    Cover
                  </label>
                  {form.errors.cover ? (
                    <p
                      style={{
                        color: '#F06548',
                        fontSize: '0.875em',
                      }}
                    >
                      {(form.errors.cover as string) || ''}
                    </p>
                  ) : null}
                  {form.values.cover && (
                    <>
                      <ul className='list-unstyled mb-2' id='dropzone-preview'>
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={'-file'}
                        >
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <img
                                  data-dz-thumbnail=''
                                  height='80'
                                  className='avatar-sm rounded bg-light'
                                  alt={'NO IMAGE'}
                                  src={
                                    'preview' in form.values.cover
                                      ? form.values.cover.preview
                                      : ''
                                  }
                                />
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'
                                >
                                  {form.values.cover?.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>
                                    {form.values.cover.formattedSize}
                                  </strong>
                                </p>
                              </Col>

                              <Col className='d-flex justify-content-end align-items-end'>
                                <Button
                                  color='danger'
                                  onClick={() => {
                                    onDeleteCover()
                                  }}
                                >
                                  {' '}
                                  Delete{' '}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </ul>
                    </>
                  )}
                  <div>
                    <Button
                      color='ghost-primary'
                      className='text-light-purple'
                      onClick={() => {
                        setMediaModalDetails({
                          isOpen: true,
                          mediaProperty: 'cover',
                          type: [AttachmentsTypesEnum.IMAGE],
                        })
                      }}
                    >
                      Select From Media Library
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm='12' className='mt-2'>
            <Row>
              <Col sm='6'>
                <div>
                  <label htmlFor='description' className='form-label'>
                    Resources
                  </label>
                  <div className='is-invalid'>
                    {' '}
                    <CKEditor
                      editor={ClassicEditor}
                      data={
                        (
                          form.values['translations'][
                            selectedLanguageIndex
                          ] as any
                        )?.resources
                      }
                      onReady={editor => {
                        // You can store the 'editor' and use when it is needed.
                      }}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData()
                        form.setFieldValue(
                          `translations[${selectedLanguageIndex}].resources`,
                          data,
                        )
                      }}
                      onBlur={() =>
                        form.handleBlur(
                          `translations[${selectedLanguageIndex}].resources`,
                        )
                      }
                    />
                  </div>
                  {translationError(selectedLanguageIndex, 'resources')}
                </div>
              </Col>

              <Col sm='6'>
                <div>
                  <label htmlFor='description' className='form-label'>
                    Notes
                  </label>
                  <div className='is-invalid'>
                    {' '}
                    <CKEditor
                      editor={ClassicEditor}
                      data={
                        (
                          form.values['translations'][
                            selectedLanguageIndex
                          ] as any
                        )?.notes
                      }
                      onReady={editor => {}}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData()
                        form.setFieldValue(
                          `translations[${selectedLanguageIndex}].notes`,
                          data,
                        )
                      }}
                      onBlur={() =>
                        form.handleBlur(
                          `translations[${selectedLanguageIndex}].notes`,
                        )
                      }
                    />
                  </div>
                  {translationError(selectedLanguageIndex, 'notes')}
                </div>
              </Col>
            </Row>
          </Col>
          <Col className='mb-4'>
            {/* {!isPublished && ( */}
            <GeneralAddFormat
              formats={course?.formats || []}
              onAdd={onAddFormat}
            />
            {/* )} */}
          </Col>
        </Row>
        <InstructorModal
          title={'New Instructor'}
          isOpen={showCreateInstructorModal}
          initialValues={null}
          onSubmit={onCreateInstructor}
          onClose={() => {
            setCreateInstructorModal(false)
          }}
        />
        <OnlineTagModal
          isOpen={showCreateKeywordsModal}
          title={'Add New Tag'}
          initialValues={{ name: '' }}
          onClose={() => {
            setCreateKeywordsModal(false)
          }}
          onSubmit={onTagCreate}
        />
      </>
    </CourseLayout>
  )
}
export default withRouter(GeneratStep)
