import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FILTERS } from '../../pages/Reporting/CourseHistory/types'
import { useUrlParams } from '../../utils/urlParamsParser'
import DetailsInfoModal from '../Modals/DetailsInfoModal'

export interface FilterTabsProps<EnumValue> {
  navTab?: string | undefined
  navToggle: (tab?: EnumValue) => void
  tabs: {
    label: string
    value: EnumValue | undefined
    info?: string
    id?: string
  }[]
  style?: React.CSSProperties
  extraTabData?: { [key: string]: string | number }
}
const FilterTabs: <SortBy>(
  props: FilterTabsProps<SortBy>,
) => React.ReactElement<FilterTabsProps<SortBy>> = ({
  navTab,
  navToggle,
  tabs,
  extraTabData,
  style,
}) => {
  const navigate = useNavigate()
  const { updateUrlParams } = useUrlParams()
  return (
    <Nav className='nav-customs-bordered m-auto' style={style}>
      {tabs.map(tab => (
        <NavItem
          key={tab.label}
          className={classnames({ active: navTab === tab.value })}
        >
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
              if (tab.value !== undefined) {
                navigate(
                  `?${updateUrlParams(
                    FILTERS.TAB,
                    tab.value ? tab.value.toString() : '',
                  )}`,
                )
              } else {
                navigate(`?${updateUrlParams(FILTERS.TAB, '')}`)
              }
            }}
          >
            {tab.label}
            {!!_.get(extraTabData, tab.value as string) && (
              <span className='ps-1'>
                ({_.get(extraTabData, tab.value as string)})
              </span>
            )}
            {tab.info && (
              <DetailsInfoModal
                name={`filter-tabs-break-down`}
                info={tab.info}
                onHover={true}
                id={tab.id as string}
              />
            )}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
