import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
} from 'reactstrap'
import { PermissionSet, TRole } from '../../../sharedTypes'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppSelector } from '../../../hooks/redux'
import _ from 'lodash'

const emptyPermissionSet: PermissionSet = {
  courses: {
    viewCourseListing: false,
    cloneCourse: false,
    addCourseRevision: false,
    assignCourse: false,
    addEditCourse: false,
    addCourseSchedule: false,
    editCourseSchedule: false,
    deleteCourseSchedule: false,
    deleteCourse: false,
    publishCourse: false,
    viewMediaLibrary: false,
    viewMedia: false,
    addMedia: false,
    editMedia: false,
    deleteMedia: false,
    addClonePackage: false,
    editPackage: false,
    addCoursesToPackage: false,
    removeCoursesFromPackage: false,
    addScheduleForPackage: false,
    editScheduleForPackage: false,
    deleteScheduleForPackage: false,
    publishPackage: false,
    deletePackage: false,
    viewTranslations: false,
    editSpanishTranslation: false,
    editCreoleTranslation: false,
    viewCourseKeywords: false,
    addCourseKeywords: false,
    editCourseKeywords: false,
    deleteCourseKeywords: false,
  },
  userManagement: {
    viewUserListing: false,
    viewUserProfile: false,
    viewUserInfo: false,
    viewUserCourseHistory: false,
    assignCourseToUser: false,
    viewUserDocuments: false,
    requestEditOfUserInfo: false,
    viewCertificate: false,
    grandAdminPortalAccess: false,
    removeAdminPortalAccess: false,
    printTranscript: false,
    deactivateUser: false,
    editAssignment: false,
    removeAssignment: false,
    viewAdminListing: false,
    addNewAdmin: false,
    editAdmin: false,
    deactivateAdmin: false,
    overrideOwnCourseResult: false,
    editOwnCourseAssignment: false,
    removeOwnAssignedCourse: false,
    viewCourseAssignmentByPosition: false,
    viewImportLog: false,
  },
  facilityManagement: {
    viewFacilityManagement: false,
    viewFacilityDetails: false,
    viewFacilityEmployees: false,
    addNewFacility: false,
    editFacility: false,
    activateFacility: false,
    deactivateFacility: false,
    addNewGroup: false,
    editGroup: false,
    deleteGroup: false,
  },
  reporting: {
    viewReports: false,
    viewCourseHistoryReport: false,
    viewUserReport: false,
    viewCourseReport: false,
    viewCourseFeedbackReport: false,
    viewCourseHistorySummaryReport: false,
  },
  support: {
    viewTutorialsListing: false,
    addTutorial: false,
    editTutorial: false,
    deleteTutorial: false,
    viewFAQListing: false,
    viewSupportCategories: false,
    addSupportCategories: false,
    editSupportCategories: false,
    deleteSupportCategories: false,
  },
  calendar: {
    viewCalendar: false,
    viewFacilitatorCourseView: false,
    addParticipants: false,
    removeParticipants: false,
    checkParticipantIn: false,
    previewTest: false,
    releaseTest: false,
    overrideTestScore: false,
  },
  communication: {
    viewSurveyListing: false,
    viewSurveys: false,
    createCloneSurvey: false,
    editSurvey: false,
    sendScheduleSurvey: false,
    viewIndividualSurveyResponses: false,
    viewSurveyResponseOverview: false,
    deleteSurvey: false,
    viewNotificationListing: false,
    viewNotifications: false,
    createCloneNotifications: false,
    editNotifications: false,
    sendScheduleNotifications: false,
    deleteNotifications: false,
    viewCompanyNewsListing: false,
    viewCompanyNews: false,
    addCompanyNews: false,
    editCompanyNews: false,
    publishCompanyNews: false,
    deleteCompanyNews: false,
    sendScheduleCompanyNews: false,
  },
  settings: {
    viewDefaultCourseSettings: false,
    editDefaultCourseSettings: false,
    viewInstructorListing: false,
    addInstructor: false,
    editInstructor: false,
    deleteInstructor: false,
    viewRolesAndPermissions: false,
    addCloneRole: false,
    editRoleName: false,
    editRolePermissions: false,
    deleteRole: false,
    assignRoleToUsers: false,
  },
}

interface CreateNewRoleModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  toggle: ModalProps['toggles']
  roles: TRole[]
  onAddedNewRole: (role: TRole) => void
}

const CreateNewRoleModal = ({
  onClose,
  toggle,
  isOpen,
  roles,
  onAddedNewRole,
}: CreateNewRoleModalProps) => {
  const user = useAppSelector(state => state.User.user)

  const createRole = ({
    name,
    copyRole,
  }: {
    name: string
    copyRole: string
  }) => {
    const roleData: TRole = {
      id: -1,
      name,
      companyId: user?.companyId || null,
      permissionSet:
        +copyRole > 0
          ? _.cloneDeep(
              roles.find(role => role.id === +copyRole)?.permissionSet,
            ) || emptyPermissionSet
          : emptyPermissionSet,
      createdAt: Date.now().toString(),
      updatedAt: Date.now().toString(),
    }
    onAddedNewRole(roleData)
    onClose()
    form.resetForm()
  }

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      copyRole: '0',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Role Name'),
    }),
    onSubmit: createRole,
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Create Role</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={toggle}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <div>
              <label htmlFor='roleName' className='form-label'>
                Name
              </label>
              <Input
                name='name'
                className='form-control'
                id='roleName'
                placeholder='Enter role name'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.name || ''}
                invalid={!!(form.touched.name && form.errors.name)}
              />
              {form.touched.name && form.errors.name ? (
                <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
              ) : null}
            </div>
            <div>
              <label htmlFor='copyRole' className='form-label'>
                Clone From
              </label>
              <select
                name='copyRole'
                className='form-select mb-3'
                id='copyRole'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.copyRole}
              >
                <option value={0}>Start with a blank one</option>
                {roles.map(role => (
                  <option value={role.id} key={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={onClose}
              active={false}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={!(form.isValid && form.dirty)}
            >
              Submit
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default CreateNewRoleModal
