import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import Select, { MultiValue, SingleValue } from 'react-select'
import _ from 'lodash-es'

import {
  CountriesEnum,
  CountriesOptions,
  StatesEnum,
  StatesOptions,
  TCompany,
  TRole,
  TUser,
  PatchAdminsDTO,
  CREDENTIALS_TYPE,
  TDepartment,
  FilterOption,
  GetRolesPermissions,
  GetCompanyFacilitiesPermissions,
  SettingsPermissions,
} from '../../sharedTypes'
import AvatarUploader from '../Common/AvaterUploader'
import {
  getAdminByEmail,
  getCompaniesHierarchy,
  getDepartments,
  getRoles,
} from '../../helpers/api_helper'
import { GroupSelectWithSearch } from '../Common/SelectWithSearch'
import {
  getCompanyGroupWithFacilitiesOptions,
  getAdminOption,
  Option,
} from '../../helpers/facility'
import MultiSelect from '../Common/MultiSelect'
import { AllValue } from './AssignCourseModal/types'
import { useAppSelector } from '../../hooks/redux'
import { usePermissions } from '../../hooks/usePermissions'

interface AdminModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (admin: PatchAdminsDTO.Request) => void
  title: string
  editAdmin?: TUser
}

interface IForm {
  id: number | null | undefined
  firstName: string
  lastName: string
  position: string
  role?: FilterOption
  email: string
  photo: string | Blob
  phone?: string
  country?: { value: CountriesEnum; label: CountriesEnum }
  state?: { value: StatesEnum; label: StatesEnum }
  group?: Option | Option[]
  departments?: FilterOption[] | FilterOption
}

type SelectionType = 'facility' | 'group' | 'company'

const getFormValues = (groupOptions: Option[], user?: TUser): IForm => {
  const email = user?.credentials?.find(
    i => i.credentialType === CREDENTIALS_TYPE.EMAIL,
  )?.credential
  const phone = user?.credentials?.find(
    i => i.credentialType === CREDENTIALS_TYPE.PHONE,
  )?.credential

  let departments = undefined
  if (user?.facilityId && _.isEmpty(user.adminDepartments)) {
    departments = AllValue
  }
  if (user?.facilityId && !_.isEmpty(user.adminDepartments)) {
    departments = user.adminDepartments?.map(d => ({
      label: d.name,
      value: d.id,
    }))
  }

  return {
    id: user ? user.id : null,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    position:
      user && user.rawPosition
        ? user.rawPosition
        : user && user.position
        ? user.position.name
        : '',
    role:
      user && user.role
        ? { value: user.role.id, label: user.role.name }
        : undefined,
    email: email || '',
    photo: user?.photo || '',
    phone: phone || '',
    country: user ? { value: user.country, label: user.country } : undefined,
    state:
      user && user.state ? { value: user.state, label: user.state } : undefined,
    group: user ? getAdminOption(groupOptions, user) : undefined,
    departments,
  }
}

const AdminModal = ({
  onClose,
  isOpen,
  title,
  editAdmin,
  onSubmit,
}: AdminModalProps) => {
  const user = useAppSelector(state => state.User.user)

  const [roles, setRoles] = useState<TRole[]>([])
  const [companies, setCompanies] = useState<TCompany[]>([])
  const [departments, setDepartments] = useState<TDepartment[]>([])
  const [emailToCheck, setEmailToCheck] = useState('')
  const [isHosted, setIsHosted] = useState(!!editAdmin?.employeeId)

  useEffect(() => {
    if (editAdmin) {
      setIsHosted(!!editAdmin?.employeeId)
    }
  }, [editAdmin])

  useEffect(() => {
    getRoles({
      permission: editAdmin
        ? GetRolesPermissions.EDIT_ADMIN
        : GetRolesPermissions.ADD_NEW_ADMIN,
    })
      .then(res => {
        setRoles(res.data)
      })
      .catch()

    getCompaniesHierarchy({
      permission: editAdmin
        ? GetCompanyFacilitiesPermissions.EDIT_ADMIN
        : GetCompanyFacilitiesPermissions.ADD_NEW_ADMIN,
    })
      .then(res => {
        setCompanies(res.data)
      })
      .catch(() => {})
  }, [])

  const groupOptions = useMemo(() => {
    return getCompanyGroupWithFacilitiesOptions(companies)
  }, [companies])

  const _onSubmit = ({
    firstName,
    lastName,
    id: adminId,
    state,
    country,
    phone,
    photo: avatar,
    email,
    position,
    role,
    group,
    departments,
  }: IForm) => {
    if (!state || !country || !role || !group) {
      return
    }

    const ids: number[] = []
    let selectionType: SelectionType = 'facility'

    const items = Array.isArray(group) ? group : [group as Option]

    items.map(item => {
      const [type, id] = item.value.split(':')
      selectionType = type as SelectionType
      ids.push(+id)
    })

    if (ids.length > 0) {
      selectionType =
        (items[0]?.value.split(':')[0] as SelectionType) ?? 'facility'
    }

    const data: PatchAdminsDTO.Request = {
      firstName,
      lastName,
      state: state.value,
      country: country.value,
      phone,
      email,
      rawPosition: position,
      roleId: +role.value,
      facilityIds: selectionType === 'facility' ? ids : null,
      groupId: selectionType === 'group' ? ids[0] : null,
      departmentIds: Array.isArray(departments)
        ? departments.map(d => +d.value)
        : undefined,
    }

    if (adminId) {
      data.id = adminId
    }

    if (avatar && typeof avatar === 'object') {
      data.avatar = avatar
    }

    if (!data.facilityIds) {
      data.departmentIds = undefined
    }

    return onSubmit(data)
  }

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: getFormValues(groupOptions, editAdmin),
    validationSchema: Yup.object({
      photo: Yup.mixed()
        .nullable()
        .test('is-valid-size', 'Max allowed size is 3mb', value =>
          value instanceof File ? value.size <= 3000000 : true,
        ),
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      position: Yup.string().required('Position is required'),
      phone: Yup.string().matches(
        /^\d{10}$/,
        'Mobile number must be exactly 10 digits',
      ),
      role: Yup.object().required('Role is required'),
      group: Yup.lazy(value =>
        Array.isArray(value)
          ? Yup.array()
              .min(1, 'Group/Facility is required')
              .required('Group/Facility is required')
          : Yup.object().required('Group/Facility is required'),
      ),
      country: Yup.object().required('Country is required'),
      state: Yup.object().required('State is required'),
    }),
    onSubmit: _onSubmit,
  })

  const selectedFacility = useMemo(() => {
    if (Array.isArray(form.values.group) && form.values.group.length === 1) {
      const [type, id] = (form.values.group[0] as Option).value.split(':')
      if (type === 'facility') {
        return +id
      }
    }

    return undefined
  }, [form.values.group])

  const checkAdminByEmail = useCallback(() => {
    const email = form.values.email
    if (!editAdmin && email && !form.errors.email && email !== emailToCheck) {
      setEmailToCheck(email)
      getAdminByEmail(email)
        .then(res => {
          if (res.data) {
            if (!form.values.id || form.values.id !== res.data.id) {
              setIsHosted(!!res.data.employeeId)
              form.setValues(getFormValues(groupOptions, res.data))
            }
          } else {
            if (form.values.id) {
              form.setFieldValue('id', -1)
            }
            setIsHosted(false)
          }
        })
        .catch(() => {})
    }
  }, [form, emailToCheck, editAdmin])

  useEffect(() => {
    if (!isOpen) {
      form.resetForm()
      setEmailToCheck('')
      setIsHosted(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (selectedFacility) {
      getDepartments({ facilityIds: [selectedFacility] })
        .then(res => {
          setDepartments(res.data.departments)
        })
        .catch(() => {})
    } else {
      form.setFieldValue('departments', undefined)
      setDepartments([])
    }
  }, [selectedFacility])

  const hasPermissionToAssignRole = usePermissions(
    SettingsPermissions.ASSIGN_ROLE_TO_USERS,
  )
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3 mb-3'>
            <section className='m-auto'>
              <AvatarUploader
                file={form.values.photo}
                onChange={(f: File) => {
                  form.setFieldValue('photo', f)
                }}
              />
              {form.errors.photo ? (
                <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                  {form.errors.photo}
                </p>
              ) : null}
            </section>
          </div>

          <div className='vstack gap-3'>
            <div>
              <label htmlFor='email' className='form-label'>
                Email*
              </label>
              <Input
                name='email'
                className='form-control'
                id='name'
                placeholder='Enter email'
                type='text'
                onChange={form.handleChange}
                onBlur={event => {
                  form.handleBlur(event)
                  checkAdminByEmail()
                }}
                disabled={form.isSubmitting || isHosted}
                value={form.values.email}
                invalid={!!(form.touched.email && form.errors.email)}
              />
              {form.touched.email && form.errors.email ? (
                <FormFeedback type='invalid'>{form.errors.email}</FormFeedback>
              ) : null}
            </div>
            <Row>
              <Col>
                <label htmlFor='firstName' className='form-label'>
                  First Name*
                </label>
                <Input
                  name='firstName'
                  className='form-control'
                  id='firstName'
                  placeholder='Enter first name'
                  type='text'
                  onChange={form.handleChange}
                  disabled={form.isSubmitting || isHosted}
                  onBlur={form.handleBlur}
                  value={form.values.firstName}
                  invalid={!!(form.touched.firstName && form.errors.firstName)}
                />
                {form.touched.firstName && form.errors.firstName ? (
                  <FormFeedback type='invalid'>
                    {form.errors.firstName}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <label htmlFor='lastName' className='form-label'>
                  Last Name*
                </label>
                <Input
                  name='lastName'
                  className='form-control'
                  id='lastName'
                  placeholder='Enter last name'
                  type='text'
                  onChange={form.handleChange}
                  disabled={form.isSubmitting || isHosted}
                  onBlur={form.handleBlur}
                  value={form.values.lastName}
                  invalid={!!(form.touched.lastName && form.errors.lastName)}
                />
                {form.touched.lastName && form.errors.lastName ? (
                  <FormFeedback type='invalid'>
                    {form.errors.lastName}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <div>
              <label htmlFor='position' className='form-label'>
                Position*
              </label>
              <Input
                name='position'
                className='form-control'
                id='position'
                placeholder='Enter position'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                disabled={form.isSubmitting || isHosted}
                value={form.values.position}
                invalid={!!(form.touched.position && form.errors.position)}
              />
              {form.touched.position && form.errors.position ? (
                <FormFeedback type='invalid'>
                  {form.errors.position}
                </FormFeedback>
              ) : null}
            </div>
            <div>
              <label htmlFor='country' className='form-label'>
                Role*
              </label>
              <Select
                isSearchable={false}
                onChange={option => {
                  form.setFieldValue('role', option)
                }}
                isDisabled={
                  form.values.id === user?.id || !hasPermissionToAssignRole
                }
                value={form.values.role}
                options={roles.map(role => ({
                  value: role.id,
                  label: role.name,
                }))}
                name='role'
                id='role'
                placeholder='Select role'
                onBlur={form.handleBlur}
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    borderRadius: '0px 4px 4px 0px',
                    minHeight: 39,
                  }),
                }}
                className='select2-container w-100'
                classNamePrefix='select2-selection form-select'
              />
              {form.touched.role && form.errors.role ? (
                <FormFeedback type='invalid'>{form.errors.role}</FormFeedback>
              ) : null}
            </div>
            <div>
              <label htmlFor='group' className='form-label'>
                Group/Facility*
              </label>
              <GroupSelectWithSearch<Option>
                name='group'
                id='group'
                onChange={(
                  option: SingleValue<Option> | MultiValue<Option>,
                ) => {
                  const selectedOptions = Array.isArray(option)
                    ? option
                    : [option]
                  const hasChildren = selectedOptions.some(
                    opt => opt && opt.hasChildren,
                  )

                  if (hasChildren) {
                    const singleValue = selectedOptions.find(
                      opt => opt && opt.hasChildren,
                    )
                    if (singleValue) {
                      form.setFieldValue('group', singleValue)
                    }
                  } else {
                    form.setFieldValue('group', selectedOptions)
                  }

                  form.setFieldValue('departments', AllValue)
                }}
                onBlur={form.handleBlur}
                value={form.values.group}
                isMulti={
                  Array.isArray(form.values.group) &&
                  form.values.group.some(option => option.hasChildren === false)
                }
                isClearable={false}
                isSearchable={true}
                placeholder={'Select group/facility'}
                options={groupOptions}
                isDisabled={form.isSubmitting || form.values.id === user?.id}
              />
              {form.touched.group && form.errors.group ? (
                <FormFeedback type='invalid'>{form.errors.group}</FormFeedback>
              ) : null}
            </div>
            {selectedFacility && !_.isEmpty(departments) ? (
              <div>
                <label htmlFor='departments' className='form-label'>
                  Departments
                </label>
                <MultiSelect
                  name='departments'
                  id='departments'
                  options={[
                    AllValue,
                    ...departments.map(department => ({
                      value: department.id,
                      label: department.name,
                    })),
                  ]}
                  isMulti={!_.isEqual(form.values.departments, AllValue)}
                  isSearchable={true}
                  placeholder='Select departments'
                  isClearable={false}
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(selectedOptions: FilterOption[]) => {
                    if (
                      _.isEmpty(selectedOptions) ||
                      _.includes(selectedOptions, AllValue)
                    ) {
                      form.setFieldValue('departments', AllValue)
                      return
                    }
                    if (_.isEqual(form.values.departments, selectedOptions)) {
                      return
                    }

                    form.setFieldValue(
                      'departments',
                      Array.isArray(selectedOptions)
                        ? selectedOptions
                        : [selectedOptions],
                    )
                  }}
                  value={form.values.departments}
                  isDisabled={form.isSubmitting}
                />
                {form.touched.departments && form.errors.departments ? (
                  <FormFeedback type='invalid'>
                    {form.errors.departments}
                  </FormFeedback>
                ) : null}
              </div>
            ) : null}

            <div>
              <label htmlFor='phone' className='form-label'>
                Mobile
              </label>
              <Input
                name='phone'
                className='form-control'
                id='phone'
                placeholder='Enter mobile'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                disabled={
                  form.isSubmitting || (isHosted && !!form.values.phone)
                }
                value={form.values.phone}
                invalid={!!(form.touched.phone && form.errors.phone)}
              />
              {form.touched.phone && form.errors.phone ? (
                <FormFeedback type='invalid'>{form.errors.phone}</FormFeedback>
              ) : null}
            </div>
            <Row>
              <Col>
                <label htmlFor='country' className='form-label'>
                  Country*
                </label>
                <Select
                  isSearchable={false}
                  onChange={option => {
                    form.setFieldValue('country', option)
                  }}
                  value={form.values.country}
                  options={CountriesOptions}
                  name='country'
                  id='country'
                  placeholder='Select country'
                  onBlur={form.handleBlur}
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      borderRadius: '0px 4px 4px 0px',
                      minHeight: 39,
                    }),
                  }}
                  className='select2-container w-100'
                  classNamePrefix='select2-selection form-select'
                  isDisabled={form.isSubmitting || isHosted}
                />
                {form.touched.country && form.errors.country ? (
                  <FormFeedback type='invalid'>
                    {form.errors.country}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <label htmlFor='state' className='form-label'>
                  State*
                </label>
                <Select
                  isSearchable={false}
                  onChange={option => {
                    form.setFieldValue('state', option)
                  }}
                  value={form.values.state}
                  options={StatesOptions}
                  name='state'
                  id='state'
                  placeholder='Select state'
                  onBlur={form.handleBlur}
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      borderRadius: '0px 4px 4px 0px',
                      minHeight: 39,
                    }),
                  }}
                  className='select2-container w-100'
                  classNamePrefix='select2-selection form-select'
                  isDisabled={form.isSubmitting || isHosted}
                />
                {form.touched.state && form.errors.state ? (
                  <FormFeedback type='invalid'>
                    {form.errors.state}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </div>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={onClose}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={!(form.isValid && form.dirty) || form.isSubmitting}
            >
              {form.isSubmitting ? <Spinner size={'sm'} /> : 'Submit'}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AdminModal
